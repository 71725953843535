<template>
  <v-card
    v-if="tmpFilter != null"
    flat
    tile
  >
    <v-card-text
      class="pb-0"
      v-if="readonly === false"
    >
      <v-checkbox
        v-model="useRestrictions"
        :label="texts.de.useRestrictions"
        @change="$emit('setDirty')"
      ></v-checkbox>
    </v-card-text>
    <v-card-text
      v-if="readonly === false && useRestrictions === true"
      class="pl-13"
    >
      <v-row>
        <v-col
          cols="12"
          lg="10"
          md="9"
          sm="12"
          xs="12"
          class="pt-0"
        >
          <v-row no-gutters>
            <span class="mt-1 caption mr-2">{{ texts.de.dealerKeys }}</span>
            <v-btn
              class="mr-2"
              v-if="readonly !== true"
              :disabled="tmpFilter.dealer.keysImported === true || tmpFilter.dealer.keys.includes(null)"
              @click="tmpFilter.dealer.keys.push(null)"
              icon
              small
              color="secondary"
            >
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
            <v-btn
              v-if="readonly !== true"
              :disabled="tmpFilter.dealer.keysImported === true"
              @click="$refs.keysImport.click()"
              small
              icon
              color="secondary"
              v-tooltip="$tooltip(texts.de.csvUpload)"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
            <input
              type="file"
              ref="keysImport"
              accept=".csv"
              @change="loadDealerKeys"
              style="display:none"
            />
            <v-btn
              v-if="tmpFilter.dealer.keysImported === true"
              @click="downloadDealerList"
              small
              icon
              color="secondary"
              v-tooltip="$tooltip(texts.de.csvDownload)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-row>
          <v-row
            v-if="dealerKeysFile != null"
            v-show="uploadFiles"
            class="mt-0 pt-0"
          >
            <v-col class="text-center">
              <S3UploadElement
                ref="dealerKeysUpload"
                :file="dealerKeysFile.file"
                :s3Config="importS3Config"
                :objectKey="fileObjectKey + '_dealerKeys.csv'"
                :cancelIcon="null"
                :autoProcess="false"
                @uploadError="handleUploadError"
                @uploadSuccess="handleUploadSuccess"
              />
            </v-col>
          </v-row>
          <v-row
            no-gutters
            v-if="tmpFilter.dealer.keysImported !== true"
          >
            <v-chip
              v-for="(v) in tmpFilter.dealer.keys"
              :key="v"
              close
              @click:close="readonly ? null : removeValue('keys', v)"
              class="mr-2 mb-1"
            >
              <EditField
                style="width: 50px"
                class="mt-0 caption"
                v-if="v == null"
                @change="changeValue('keys', v, $event)"
                :value="$filters.formatNumber(v, null, NULLVALUE)"
                :placeholder="$t('base.value')"
                hideDetails
                dense
                type="uint"
              />
              <div v-else>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
            </v-chip>
            <span v-if="tmpFilter.dealer.keys.length === 0">{{ $t('condAdmin.noRestriction') }}</span>
          </v-row>
          <v-row
            no-gutters
            v-else
          >
            <v-chip
              close
              @click:close="readonly ? null : removeImportedDealerKeys()"
              class="mr-2 mb-1"
            >
              {{ tmpFilter.dealer.keys.length + texts.de.selectedDealerIds }}
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="4"
          class="pt-0"
        >
          <v-select
            v-model="tmpFilter.dealer.buckets"
            :items="buckets"
            :placeholder="$t('condAdmin.noRestriction')"
            attach
            chips
            :label="texts.de.buckets"
            multiple
            hide-details
            :readonly="readonly"
            @change="$emit('setDirty')"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="pt-0"
        >
          <v-select
            v-model="tmpFilter.dealer.performances"
            :items="performances"
            :placeholder="$t('condAdmin.noRestriction')"
            attach
            chips
            :label="texts.de.performances"
            multiple
            hide-details
            :readonly="readonly"
            @change="$emit('setDirty')"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="4"
          class="pt-0"
        >
          <v-select
            v-model="tmpFilter.dealer.types"
            :items="types"
            item-text="n"
            item-value="v"
            :placeholder="$t('condAdmin.noRestriction')"
            attach
            chips
            :label="texts.de.types"
            multiple
            hide-details
            :readonly="readonly"
          ></v-select>
        </v-col>
        <v-col
          v-if="tmpFilter.dealer.types.includes('MARKEN_HAENDLER')"
          cols="12"
          lg="8"
          class="pt-0"
        >
          <v-row no-gutters>
            <span class="mt-1 caption mr-2">{{ texts.de.brands}}</span>
            <v-btn
              v-if="readonly !== true"
              :disabled="tmpFilter.dealer.brands.includes(null)"
              @click="tmpFilter.dealer.brands.push(null)"
              icon
              small
              color="secondary"
            >
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </v-row>
          <v-row no-gutters>
            <v-chip
              v-for="(v) in tmpFilter.dealer.brands"
              :key="v"
              close
              @click:close="readonly ? null : removeValue('brands', v)"
              class="mr-2 mb-1"
            >
              <EditField
                style="width: 50px"
                class="mt-0 caption"
                v-if="v == null"
                @change="changeValue('brands', v, $event)"
                :value="$filters.formatNumber(v, null, NULLVALUE)"
                :placeholder="$t('base.value')"
                hideDetails
                dense
                type="uint"
              />
              <div v-else>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
            </v-chip>
            <span v-if="tmpFilter.dealer.brands.length === 0">{{ $t('condAdmin.noRestriction') }}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- ### readonly-Part -->
    <v-card-text
      v-if="readonly === true && useRestrictions === false"
      class="pl-4"
    >
      <v-row>
        <v-col
          cols="12"
          class="pt-0 body-1"
        >
          {{ texts.de.noRestrictions }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text
      v-if="readonly === true && useRestrictions === true"
      class="pl-4"
    >
      <v-row>
        <v-col
          cols="12"
          class="pt-0 body-1"
        >
          {{ texts.de.useRestrictions }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="10"
          md="9"
          sm="12"
          xs="12"
          class="pt-0"
        >
          <v-row no-gutters>
            <span class="mt-1 caption mr-2">{{ texts.de.dealerKeys }}</span>
            <v-btn
              v-if="tmpFilter.dealer.keysImported === true"
              @click="downloadDealerList"
              small
              icon
              color="secondary"
              v-tooltip="$tooltip(texts.de.csvDownload)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-row>
          <v-row
            no-gutters
            v-if="tmpFilter.dealer.keysImported !== true"
          >
            <v-chip
              v-for="(v) in tmpFilter.dealer.keys"
              :key="v"
              class="mr-2 mb-1"
            >
              <div>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
            </v-chip>
            <span v-if="tmpFilter.dealer.keys.length === 0">{{ $t('condAdmin.noRestriction') }}</span>
          </v-row>
          <v-row
            no-gutters
            v-else
          >
            <v-chip class="mr-2 mb-1">
              {{ tmpFilter.dealer.keys.length + texts.de.selectedDealerIds }}
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="4"
          class="pt-0"
        >
          <v-row no-gutters>
            <span class="mt-1 caption mr-2">{{ texts.de.buckets }}</span>
          </v-row>
          <v-row no-gutters>
            <v-chip
              v-for="(v) in tmpFilter.dealer.buckets"
              :key="v"
              class="mr-2 mb-1"
            >
              <div>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
            </v-chip>
            <span v-if="tmpFilter.dealer.buckets.length === 0">{{ $t('condAdmin.noRestriction') }}</span>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="pt-0"
        >
          <v-row no-gutters>
            <span class="mt-1 caption mr-2">{{ texts.de.performances }}</span>
          </v-row>
          <v-row no-gutters>
            <v-chip
              v-for="(v) in tmpFilter.dealer.performances"
              :key="v"
              class="mr-2 mb-1"
            >
              <div>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
            </v-chip>
            <span v-if="tmpFilter.dealer.performances.length === 0">{{ $t('condAdmin.noRestriction') }}</span>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="4"
          class="pt-0"
        >
          <v-row no-gutters>
            <span class="mt-1 caption mr-2">{{ texts.de.types }}</span>
          </v-row>
          <v-row no-gutters>
            <v-chip
              v-for="(v) in tmpFilter.dealer.types"
              :key="v"
              class="mr-2 mb-1"
            >
              <div>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
            </v-chip>
            <span v-if="tmpFilter.dealer.types.length === 0">{{ $t('condAdmin.noRestriction') }}</span>
          </v-row>
        </v-col>
        <v-col
          v-if="tmpFilter.dealer.types.includes('MARKEN_HAENDLER')"
          cols="12"
          lg="8"
          class="pt-0"
        >
          <v-row no-gutters>
            <span class="mt-1 caption mr-2">{{ texts.de.brands}}</span>
          </v-row>
          <v-row no-gutters>
            <v-chip
              v-for="(v) in tmpFilter.dealer.brands"
              :key="v"
              class="mr-2 mb-1"
            >
              <div>{{ $filters.formatNumber(v, null, NULLVALUE) }}</div>
            </v-chip>
            <span v-if="tmpFilter.dealer.brands.length === 0">{{ $t('condAdmin.noRestriction') }}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import deepmerge from '@/base/js/Deepmerge.js';
import EditField from '@/common/EditField';
import { newMessage } from '@/base/js/ProcessDataHelper';
import S3UploadElement from '@/common/S3UploadElement';
import { saveAs } from 'file-saver';
import { S3Helper } from '@/base/js/aws/S3Helper';
import commonMixin from '@/common/js/commonMixin.js';

export default {
  name: 'ConditionCommissionSettingsYareto',
  mixins: [commonMixin],
  components: {
    EditField,
    S3UploadElement
  },
  props: {
    objectKey: { type: String, required: true },
    filter: { type: Object, required: true },
    commissionUid: { type: String, required: true },
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      tmpFilter: null,
      useRestrictions: false,
      texts: {
        de: {
          useRestrictions: 'Händler- und markenbezogene Einschränkungen',
          noRestrictions: 'Keine händler- und markenbezogene Einschränkungen',
          dealerKeys: 'Liste zugelassener Händler (IDs)',
          buckets: 'zugelassene Buckets',
          performances: 'zugelassene Potentiale',
          types: 'zugelassene Händlertypen',
          brands: 'Marken (IDs)',
          csvUpload: 'CSV-Upload',
          csvDownload: 'CSV-Liste runterladen',
          invalidKeyImportData: 'Fehler beim Upload der CSV-Datei',
          invalidDealerIdInCsv: 'Ungültige HändlerID in Zeile ',
          selectedDealerIds: ' Händler zugelassen'
        }
      },
      defaultFilter: {
        dealer: {
          brands: [],
          buckets: [],
          keys: [],
          keysImported: false,
          performances: [],
          types: []
        }
      },
      dealerKeysFile: null,
      uploadFiles: false,
      importS3Config: null,
      buckets: [1, 2, 3, 4, 5, 6],
      performances: ['A', 'B', 'C', 'D', 'E', 'X'],
      types: [{ n: 'Frei', v: 'FREIER_HAENDLER' }, { n: 'Marken', v: 'MARKEN_HAENDLER' }, { n: 'EU', v: 'EU_HAENDLER' }]

    };
  },
  computed: {
    fileObjectKey () {
      return this.objectKey + '/comm_' + this.commissionUid;
    }
  },
  watch: {
    filter () {
      if (this.filter != null && this.filter.dealer != null) this.useRestrictions = true;
      this.tmpFilter = deepmerge(this.defaultFilter, this.filter);
    }
  },
  mounted () {
    if (this.filter != null && this.filter.dealer != null) this.useRestrictions = true;
    this.tmpFilter = deepmerge(this.defaultFilter, this.filter);
    this.importS3Config = this.$configHelper.getModuleConfigParam('condAdmin', 'importS3.config');
  },
  methods: {
    // called from parent component, to fetch changed data
    getCustomParams (condition) {
      let comTable = condition.commissionParams.find((e) => e.uid === this.commissionUid);
      if (this.useRestrictions === false) comTable.filter = {};
      else {
        comTable.filter = this.tmpFilter;
        if (comTable.filter.dealer.buckets.length === 0) delete comTable.filter.dealer.buckets;
        if (comTable.filter.dealer.keys.length === 0) delete comTable.filter.dealer.keysImported;
        if (comTable.filter.dealer.performances.length === 0) delete comTable.filter.dealer.performances;
        if (comTable.filter.dealer.types.length === 0) delete comTable.filter.dealer.types;
        if (comTable.filter.dealer.brands.length === 0 || !comTable.filter.dealer.types.includes('MARKEN_HAENDLER')) delete comTable.filter.dealer.brands;
      }
    },
    // validateCustomParams (condition, messages) {
    // },
    async doCustomSaveActions (condition) {
      let comTable = condition.commissionParams.find((e) => e.uid === this.commissionUid);
      if (this.tmpFilter.dealer.keysImported === true && this.dealerKeysFile == null) {
        comTable.filter.dealer.keysImported = false;
      }
      if (this.tmpFilter.dealer.keysImported === true && this.dealerKeysFile != null) {
        this.uploadFiles = true;
        await this.$refs.dealerKeysUpload.uploadFile();
      }
    },
    removeValue (element, v) {
      let index = this.tmpFilter.dealer[element].indexOf(v);
      if (index > -1) {
        this.tmpFilter.dealer[element].splice(index, 1);
        this.$emit('setDirty');
      }
    },
    changeValue (element, oldValue, newValue) {
      let index = this.tmpFilter.dealer[element].indexOf(oldValue);
      if (index > -1) {
        this.tmpFilter.dealer[element][index] = newValue;
        this.tmpFilter.dealer[element] = [...new Set(this.tmpFilter.dealer[element].sort((a, b) => { return a - b; }))];
        this.$emit('setDirty');
      }
    },
    loadDealerKeys (e) {
      let files = e.target.files || e.dataTransfer.files;
      let reader = new FileReader();
      reader.addEventListener('load', () => {
        try {
          if (this.setLoadedDealerIds(reader.result) === true) {
            this.dealerKeysFile = {
              file: files[0],
              content: reader.result
            };
            this.tmpFilter.dealer.keysImported = true;
          }
        } catch (err) {
          this.$globals.Info.open([ newMessage('userError', this.texts.de.invalidKeyImportData) ]);
        }
      }, false);
      if (files[0] != null) {
        reader.readAsText(files[0]);
        this.$emit('setDirty');
      }
    },
    handleUploadSuccess (file, objectKey) {
      console.log('uploaded', file.name, 'as', objectKey);
      this.uploadFiles = false;
      this.dealerKeysFile = null;

      // this.$emit('save');
    },
    handleUploadError (err) {
      this.uploadFiles = false;
      this.dealerKeysFile = null;
      this.$globals.Info.open([ newMessage('systemError', this.texts.de.dealerKeysUploadError + ': ' + err.message) ]);
    },
    setLoadedDealerIds (csv) {
      let keys = [];
      let success = true;
      let rows = csv.split(/\r\n|\n/);
      for (let i = 1; i < rows.length - 1; i++) { // -1 weil letzte Zeile auch mit \r\n endet und bei split dann eine Leerzeile aam Ende entsteht
        let cols = rows[i].split(';');
        if (Number.isInteger(Number(cols[0]))) {
          keys.push(Number(cols[0]));
        } else {
          success = false;
          this.$globals.Info.open([ newMessage('userError', this.texts.de.invalidDealerIdInCsv + (i + 1)) ]);
        }
      }
      if (success === true) this.tmpFilter.dealer.keys = keys;
      return success;
    },
    removeImportedDealerKeys () {
      this.tmpFilter.dealer.keys = [];
      this.tmpFilter.dealer.keysImported = false;
      this.$emit('setDirty');
    },
    async downloadDealerList () {
      console.log(this.dealerKeysFile);
      if (this.dealerKeysFile != null) {
        // try {
        // let blob = new Blob([this.dealerKeysFile.content], { type: 'application/vnd.ms-excel' });

        // console.log(blob);
        saveAs(this.dealerKeysFile.file);
        // } catch (err) {
        //   console.log(err.message);
        // }
      } else {
        let data = await S3Helper.downloadObject(this.fileObjectKey + '_dealerKeys.csv', this.importS3Config);
        const dataString = data.toString('utf8');
        console.log(dataString);
        let blob = new Blob([dataString], { type: 'application/vnd.ms-excel' });
        console.log(blob);
        saveAs(blob, 'dealerKeys.csv');
      }
    }
  }

};
</script>

<style scoped>
</style>
